import { LINE_ITEM_STATUSES, LINE_ITEM_TYPES } from "@/constants";
import { CanopyOsI18NSchema } from "@/context/i18n/types/generatedTypes";
import { RecursivePartial } from "@/types";
import { FilterConfiguration } from "@canopyinc/aura";

export const getTransactionTableFilters = (
  dictionary?: RecursivePartial<CanopyOsI18NSchema["components"]["transactions_view"]["list"]["filters"]>
): { [key in string]?: FilterConfiguration } => ({
  ISSUED_AT_AFTER: {
    name: "issued_at_after",
    displayName: dictionary?.issued_at_after?.label,
    type: "date",
  },
  ISSUED_AT_BEFORE: {
    name: "issued_at_before",
    displayName: dictionary?.issued_at_before?.label,
    type: "date",
  },
  TRANSACTION_TYPE: {
    name: "transaction_type",
    displayName: dictionary?.line_item_type?.label,
    options: [
      {
        name: dictionary?.line_item_type?.options?.charge ?? "Charge",
        value: LINE_ITEM_TYPES.CHARGE,
      },
      {
        name: dictionary?.line_item_type?.options?.credit_offset ?? "Credit Offset",
        value: LINE_ITEM_TYPES.CREDIT_OFFSET,
      },
      {
        name: dictionary?.line_item_type?.options?.debit_offset ?? "Debit Offset",
        value: LINE_ITEM_TYPES.DEBIT_OFFSET,
      },
      {
        name: dictionary?.line_item_type?.options?.fee ?? "Fee",
        value: [
          LINE_ITEM_TYPES.CREDIT_OFFSET_FEE,
          LINE_ITEM_TYPES.MANUAL_FEE,
          LINE_ITEM_TYPES.ORIG_FEE,
          LINE_ITEM_TYPES.RECURRING_FEE,
          LINE_ITEM_TYPES.RETURN_CHECK_FEE,
        ].join(),
      },
      {
        name: dictionary?.line_item_type?.options?.interest ?? "Interest",
        value: [LINE_ITEM_TYPES.CREDIT_OFFSET_INTEREST, LINE_ITEM_TYPES.INTEREST, LINE_ITEM_TYPES.AM_INTEREST].join(),
      },
      {
        name: dictionary?.line_item_type?.options?.loan ?? "Loan",
        value: LINE_ITEM_TYPES.LOAN,
      },
      {
        name: dictionary?.line_item_type?.options?.payment ?? "Payment",
        value: LINE_ITEM_TYPES.PAYMENT,
      },
      {
        name: dictionary?.line_item_type?.options?.payment_reversal ?? "Payment Reversal",
        value: LINE_ITEM_TYPES.PAYMENT_REVERSAL,
      },
    ],
  },
  TRANSACTION_SUPER_TYPE: {
    name: "transaction_super_type",
    displayName: dictionary?.line_item_super_type?.label ?? "Transaction Super Type",
    type: "list",
    options: [
      { name: "Fee", value: "FEE" },
      { name: "Interest", value: "INTEREST" },
      { name: "Payment", value: "PAYMENT" },
      { name: "Principal", value: "PRINCIPAL" },
    ],
  },
  TRANSACTION_STATUS: {
    name: "transaction_status",
    displayName: dictionary?.line_item_status?.label,
    type: "tiered_list",
    options: [
      {
        displayName: dictionary?.line_item_status?.options?.valid?.label ?? "Valid",
        options: [
          {
            name: dictionary?.line_item_status?.options?.valid?.options?.valid ?? "Valid",
            value: LINE_ITEM_STATUSES.VALID,
          },
          {
            name: dictionary?.line_item_status?.options?.valid?.options?.retro_valid ?? "Retroactively Valid",
            value: LINE_ITEM_STATUSES.RETRO_VALID,
          },
          {
            name: dictionary?.line_item_status?.options?.valid?.options?.authorized ?? "Authorized",
            value: LINE_ITEM_STATUSES.AUTHORIZED,
          },
          {
            name: dictionary?.line_item_status?.options?.valid?.options?.reversed ?? "Reversed",
            value: LINE_ITEM_STATUSES.REVERSED,
          },
        ],
      },
      {
        displayName: dictionary?.line_item_status?.options?.invalid?.label ?? "Invalid",
        options: [
          {
            name: dictionary?.line_item_status?.options?.invalid?.options?.invalid ?? "Invalid",
            value: LINE_ITEM_STATUSES.INVALID,
          },
          {
            name: dictionary?.line_item_status?.options?.invalid?.options?.declined ?? "Declined",
            value: LINE_ITEM_STATUSES.DECLINED,
          },
          {
            name: dictionary?.line_item_status?.options?.invalid?.options?.void ?? "Void",
            value: LINE_ITEM_STATUSES.VOID,
          },
        ],
      },
      {
        displayName: dictionary?.line_item_status?.options?.pending?.label ?? "Pending",
        options: [
          {
            name: dictionary?.line_item_status?.options?.pending?.options?.pending ?? "Pending",
            value: LINE_ITEM_STATUSES.PENDING,
          },
          {
            name: dictionary?.line_item_status?.options?.pending?.options?.processing ?? "Processing",
            value: LINE_ITEM_STATUSES.PROCESSING,
          },
        ],
      },
    ],
  },
  ORIGINAL_AMOUNT: {
    name: "amount",
    displayName: dictionary?.original_amount?.label,
    type: "range",
    min: { name: "min_original_amount_cents", displayName: dictionary?.original_amount?.min },
    max: { name: "max_original_amount_cents", displayName: dictionary?.original_amount?.max },
  },
});
